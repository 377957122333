import * as React from "react"
// import Layout from "../components/layout"
import Seo from "../components/seo"
import Snake from "snake-game-react"

const isBrowser = typeof window !== `undefined`

const NotFoundPage = () => {
  if (!isBrowser) {
    return null
  } else {
    return (
      <div className="bg-[#c4bebb] text-zinc-700 ">
        <Seo title="404: Not found" />
        <div className="container mx-auto text-center py-10">
          <div className="led-red inline-flex mr-3 mb-1"></div>
          <h1 className="industryBase text-[#1a1e6e] font-bold text-xl lg:text-3xl mb-0 inline-flex ">
            Not-found
          </h1>
          <h1 className="industry3D text-[#1a1e6e] font-bold text-5xl lg:text-8xl mb-0 inline-flex pr-3 ">
            404
          </h1>
          <p className="lg:text-lg text-sm">This page doesn't exist... yet.</p>
          <div className="lg:w-[60%] w-[98%] mx-auto bg-[#555a56] p-4 rounded-br-[3rem] rounded-t-xl rounded-bl-xl">
            <span className="text-white text-xs tracking-widest gb-border">
              DOT COM WITH RETRO FUN
            </span>
            <Snake
              color1="#3d7f5e"
              color2="#3d7f5e"
              backgroundColor="#85cd70"
            />
          </div>
          <div className="flex justify-around md:w-1/3 mx-auto w-full my-4">
            <a href="/" className="flex flex-col gb-button">
              <span>HOMEPAGE</span>
              <div></div>
            </a>
            <a href="/projects" className="flex flex-col gb-button">
              <span>PROJECTS</span>
              <div></div>
            </a>
          </div>

          <p className="lg:text-lg text-sm px-4 mt-4">
            Head back to our homepage, check out our projects or stick around
            and play some games.
          </p>
          <p className="text-sm lg:block hidden">
            Use your arrow keys to play.
          </p>
        </div>
      </div>
    )
  }
}
export default NotFoundPage
